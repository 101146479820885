import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ShareService} from './services/share.service';

enum Logos {
  KBC = 'KBC',
  CBC = 'CBC',
  KBC_BRUSSELS = 'KBC_BRUSSELS',
}
@Component({
  selector: 'app-root',
  standalone: false,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public distribution: Logos = Logos.KBC;
  private destroyed$: Subject<void> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly shareService: ShareService,
  ) {}

  public ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      const distribution = params.distribution?.toUpperCase();

      if (Object.values(Logos).includes(distribution)) {
        this.distribution = distribution;
        // should be kbcbrussels.be, not kbc_brussels.be
        this.shareService.bankingEntity = this.distribution.toLowerCase().replace('_', '');
        this.shareService.distribution = this.distribution.toLowerCase();
      }
    });
  }

  public ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
